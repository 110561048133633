/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer;
}

.btn, .jr-btn {
  position: relative;
  min-width: 10px !important;
  min-height: 10px !important;
  font-size: $btn-font-size !important;
  font-weight: $btn-font-weight !important;
  text-transform: capitalize !important;
  line-height: $btn-line-height !important;
  @include border-radius($border-radius-sm !important);
  cursor: pointer;
}

.rounded-button {
  border-radius: 50%!important;
  position: relative;
  min-width: 50px !important;
  min-height: 50px !important;
  //box-shadow: 0px 3px 8px #666;
  margin: 10px!important;
}
.rounded-button-color {
  color: #FDFEFE;
}

.button-inicio-color {
  color: #fff;
  background-color: #3f51b5;
}

.btn {
  &:focus,
  &:active {
    outline: 0 none !important;
    @include box-shadow(none !important);
  }
}
.color_radio {
  color: #dc3545;
}

.button_inicio{
  background-color: #db020a !important;
  color: #ffff!important;
}

.btn_r_color {
  color: #fff!important;
  background-color: #607d8b!important;
  border-color: #607d8b!important;
}

.div_return_btn {
  margin-top: 0px;
  margin-bottom: 3px;
}


.custom-file-upload {//Este es para el label del boton input para subir imagen de marca
    border: 1px solid #db020a;
    display: block;
    padding: 6px 23px;
    color: #fff!important;
    background-color: #db020a;
    cursor: pointer;
    font-family:"Roboto", "Helvetica", "Arial", sans-serif;
    margin-right: 10px;

    i {
      position: relative;
    }
}
.carga[type="file"] {//para el input que sube los archivos de la marca
    display: none;
}
.text-uppercase {
  text-transform: uppercase !important;
}

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.jr-fab-btn {
  @extend %size-50;
  min-height: 10px !important;
}

.jr-btn {
  padding: $btn-padding !important;

  & i + span,
  & span + i {
    margin-left: 6px;
  }
}

.jr-btn-group {
  margin: 0 -10px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .jr-btn,
  & .jr-fab-btn {
    margin: 0 10px 18px;
  }
}

.jr-btn-rounded {
  @include border-radius(50px !important);
}

.jr-btn-lg {
  padding: $btn-padding-lg !important;
}

.jr-btn-sm {
  padding: $btn-padding-sm !important;
}

.jr-btn-xs {
  padding: $btn-padding-xs !important;
}

// Fab Button
.jr-btn-fab-lg {
  @extend %size-60;
}

.jr-btn-fab-sm {
  @extend %size-40;
}

.jr-btn-fab-xs {
  @extend %size-30;
}

.jr-btn-fab-lg,
.jr-btn-lg {
  font-size: $btn-font-size-lg !important;
  & i {
    font-size: ($btn-font-size-lg + 0.125rem) !important;
  }
}

.jr-btn-fab-sm,
.jr-btn-sm {
  font-size: $btn-font-size-sm !important;
  & i {
    font-size: $btn-font-size-sm !important;
  }
}

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: $btn-font-size-xs !important;
  & i {
    font-size: $btn-font-size-xs !important;
  }
}

.complex-btn-wrapper {
  @include display-flex();
  min-width: 200px;
  width: 100%;
}

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 100% !important;
    height: 100px !important;
  }
  &:hover {
    z-index: 1;
    & .img-btn-overlay {
      opacity: 0.15;
    }

    & .img-marked {
      opacity: 0;
    }

    & .img-title {
      border: 4px solid currentColor;
    }
  }

  .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    @include align-items(center);
    @include justify-content(center);
    color: $white;
  }
  .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%;
  }

  .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $black;
    opacity: 0.4;
    @include transition(all 0.5s $transition-ease);
  }

  .img-title {
    position: relative;
    padding: 16px 32px 14px;
  }
  .img-marked {
    height: 3px;
    width: 18px;
    background: $white;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    @include transition(all 0.5s $transition-ease);
  }
}

.btn-group-mins {
  margin: 0 -5px;

  & .btn,
  & .jr-btn {
    margin: 0 5px 5px;
  }
}

//buttons in label

.jr-btn.jr-btn-label i {
  background-color: rgba($white, 0.2);
  width: $size-40;
  height: 100%;
  line-height: 42px;
}

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.left i + span {
  margin-left: $size-30;
}

.jr-btn.jr-btn-label.right i + span {
  margin-right: $size-30;
  margin-left: 0;
}

.jr-btn.jr-btn-label.jr-btn-lg i {
  line-height: 54px;
}

.jr-btn.jr-btn-label.jr-btn-sm i {
  line-height: 32px;
}

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: $size-30;
}

.jr-link,
.card-link {
  cursor: pointer;
  color: $app-primary;

  &:hover,
  &:focus {
    color: darken($app-primary, 10%);
  }
}

.submit-feedback {
  padding: 0.85rem;
  font-size: 1.25rem !important;
}

.btn_flotante {
        position:fixed !important;
        z-index: 100;
        margin: 3em 0em 10em 1em!important;
}
@media only screen and (max-device-width : 1024px) {
  .btn_flotante {
          position:fixed !important;
          z-index: 100;
          margin: 0em 0em 0em -1em !important
  }
}
@media only screen and (max-device-width : 768px){
  .btn_flotante {
          position:fixed !important;
          z-index: 100;
          margin: 0em 0em 0em -2em !important
  }
}
@media only screen and (max-device-width : 480px){
  .btn_flotante {
          position:fixed !important;
          z-index: 100;
          margin: 0em 0em 0em -2em !important
  }
}
