/** Picker Styles **/
.MuiPickersToolbar-toolbar {
  background-color: #dc3545 !important;
}

.MuiPickersDay-current {
  .MuiIconButton-label {
    color: #dc3545 !important;
  }
}

.MuiPickersDay-isSelected {
  background-color: #dc3545 !important;
  .MuiIconButton-label {
    color: #ffffff !important;
  }
}

.MuiPickersClock-pin {
  background-color: #dc3545 !important;
}

.MuiPickersClockPointer-pointer {
  background-color: #dc3545 !important;
}
.MuiPickersClockPointer-thumb {
  background-color: #dc3545 !important;
  border: 14px solid #dc3545 !important;
}

.Mui-selected {
  color: #dc3545 !important;
}
