/*Preview Instrumento Styles*/

.preview-instrumento {
    background-size: cover !important;
    position: relative;
    width: 100%;
    overflow: auto;

    .card-separador {

      .card-heading {
          font-size: 1.75rem;
      }

    }

    .jr-card {
      padding: 30px 40px 30px 30px;
    }

    .btn-drag {
      position: absolute;
      top: 12px;
      right: 10px;
      width: 50px;

      button {
          min-width: 50px;
      }

    }

    .title-mensaje-inicio{
      margin-top: 120px;
    }

    .mt-custom-no-preguntas {
        margin-top: 10%;
        margin-bottom: 20%;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    }

    .content-avance {
      flex-wrap: wrap;
      display: flex;
      margin-left: 0;
      margin-right: 0;
    }

    .content-progress-bar {
      background: rgba(255, 255, 255, 0.8);
      padding: 11px;
      border-radius: 8px 0px 0px 8px;

      .content-steps {
        font-size: 13px;

        .left-steps {
          float: left;
        }

        .right-steps {
          float: right;
        }

      }

      .progress {
        height: 10px;
        float: left;
        width: 100%;
      }
    }

    .content-btn-feedback {
      padding: 9px;
      max-width: 20%;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 0px 8px 8px 0px;
    }


    .col-custom-btn {
      max-width: 21%;
      flex: 0 0 21%;
      display: block;
    }

    .content-btn-feedback-responsive {
      display: none;
    }

    @media screen and (max-width: 768px) {
      .col-custom-btn {
          max-width: 34%;
          flex: 0 0 34%;
      }
    }

    @media screen and (max-width: 480px) {
      .content-progress-bar {
        border-radius: 8px;
      }
      .col-custom-btn {
          display: none;
      }
      .content-btn-feedback-responsive {
        display: block;
        width: 100%;

        .prev-feedback {
            margin-left : 15px;
            margin-right: 15px;
        }

        .next-feedback {
          margin-left : 15px;
          margin-right: 15px;
        }

      }
    }

    .text-pregunta-requerida {
      text-align: right;
      margin-top: 1rem;
      margin-right: 1rem;
      display: block;
    }

}

.preview-instrumento.background {
  //background: url("../../assets/images/background-form-feedback.jpg") no-repeat center center fixed;
  background-size: cover;
  padding-top: 120px !important;

  .titulo-no-preguntas {
    color: #000000;
    font-size: 1.5rem;
  }

}

.preview-instrumento.sin_background {

  .titulo-no-preguntas {
    color: #252525;
    font-size: 1.5rem;
  }

}


.MuiCircularProgress-colorPrimary {
    color: #dc3545 !important;
}

.MuiMenuItem-root {
  white-space: normal !important;
}

@media screen and (max-width: 480px) {
  #message-id {
    font-size: 12px;
  }
}
