@font-face {
  font-family: 'cemtrik-icons';
  src:  url('fonts/cemtrik-icons.eot?tg7i8p');
  src:  url('fonts/cemtrik-icons.eot?tg7i8p#iefix') format('embedded-opentype'),
    url('fonts/cemtrik-icons.ttf?tg7i8p') format('truetype'),
    url('fonts/cemtrik-icons.woff?tg7i8p') format('woff'),
    url('fonts/cemtrik-icons.svg?tg7i8p#cemtrik-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cemtrik-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cemtrik-bold-start:before {
  content: "\e900";
}
.icon-cemtrik-bold-start-o:before {
  content: "\e901";
}
.icon-cemtrik-clipboard:before {
  content: "\e902";
}
.icon-cemtrik-heart:before {
  content: "\e903";
}
.icon-cemtrik-heart-o:before {
  content: "\e904";
}
.icon-cemtrik-in-love:before {
  content: "\e905";
}
.icon-cemtrik-light-start:before {
  content: "\e906";
}
.icon-cemtrik-light-start-o:before {
  content: "\e907";
}
.icon-cemtrik-neutral:before {
  content: "\e908";
}
.icon-cemtrik-sad:before {
  content: "\e909";
}
.icon-cemtrik-semi-neutral:before {
  content: "\e90a";
}
.icon-cemtrik-smile:before {
  content: "\e90b";
}
.icon-cemtrik-yelling:before {
  content: "\e90c";
}
